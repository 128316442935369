import { createSlice } from '@reduxjs/toolkit';
import { createProthesist, deleteProthesist, getProthesists, updateProthesist, } from './prothesistThunk';
const initialState = {
    data: [],
    prosthetistToUpdate: {},
    loading: false,
    createProthesistStatus: false,
    deleteProthesistStatus: false,
    updateProthesistStatus: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'prothesist',
    initialState,
    reducers: {
        setProsthetistToUpdate: (state, action) => {
            state.prosthetistToUpdate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProthesists.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getProthesists.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getProthesists.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(createProthesist.pending, (state, actions) => {
            state.createProthesistStatus = true;
        })
            .addCase(createProthesist.fulfilled, (state, { payload }) => {
            state.createProthesistStatus = false;
        })
            .addCase(createProthesist.rejected, (state, { payload }) => {
            state.error = true;
            state.createProthesistStatus = false;
        })
            .addCase(updateProthesist.pending, (state, actions) => {
            state.updateProthesistStatus = true;
        })
            .addCase(updateProthesist.fulfilled, (state, { payload }) => {
            state.updateProthesistStatus = false;
        })
            .addCase(updateProthesist.rejected, (state, { payload }) => {
            state.error = true;
            state.updateProthesistStatus = false;
        })
            .addCase(deleteProthesist.pending, (state, actions) => {
            state.deleteProthesistStatus = true;
        })
            .addCase(deleteProthesist.fulfilled, (state, { payload }) => {
            state.deleteProthesistStatus = false;
        })
            .addCase(deleteProthesist.rejected, (state, { payload }) => {
            state.error = true;
            state.deleteProthesistStatus = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { setProsthetistToUpdate } = slice.actions;
